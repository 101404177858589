import React from 'react';
import SEO from '../../components/seo/SEO';
import Layout from '../../layouts/index';
import Email from '../../components/Email';
import { graphql } from 'gatsby';

const Contact = props => (
  <Layout bodyClass="page-contact">
    <SEO 
      title="Contacto" 
      url="/contacto/" 
      description="Aquí podrá encontrar las diversas formas de contacto al despacho de Iris Buforn Abogada en Oviedo - Asturias"
    />
    <div className="intro">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Contacto</h1>
          </div>
        </div>
      </div>
    </div>

    <div className="container pb-5 pb-md-7">
      <div className="row justify-content-left">

        <div key="1" className="col-12 col-md-6 col-lg-4 mb-2">
          <div className="feature">
            <div className="feature-image">
              <img src="/contact/undraw_mail_box_kd5i.svg" />
            </div>
            <h2 className="feature-title">Email</h2>
            <div className="feature-content">
              <Email localpart={props.data.site.siteMetadata.contact.email.localpart} domain={props.data.site.siteMetadata.contact.email.domain} size="16" />
            </div>
          </div>
        </div>
        <div key="2" className="col-12 col-md-6 col-lg-4 mb-2">
          <div className="feature">
            <div className="feature-image">
              <img src="/contact/undraw_mobile_prle.svg" />
            </div>
            <h2 className="feature-title">Teléfono</h2>
            <div className="feature-content">
              {props.data.site.siteMetadata.contact.phone}
            </div>
          </div>
        </div>
        <div key="3" className="col-12 col-md-6 col-lg-4 mb-2">
          <div className="feature">
            <div className="feature-image">
              <img src="/contact/undraw_map_light_6ttm.svg" />
            </div>
            <h2 className="feature-title">Dirección</h2>
            <div className="feature-content">
              {props.data.site.siteMetadata.contact.address}<br/>
              <a href="https://goo.gl/maps/x4WDdpnVy1C2" target="_blank">¿Cómo llegar?</a>
            </div>
          </div>
        </div>
        <div key="4" className="col-12 col-md-6 col-lg-4 mb-2">
          <div className="feature">
            <div className="feature-image">
              <img src="/contact/undraw_calendar_dutt.svg" />
            </div>
            <h2 className="feature-title">Horario</h2>
            <div className="feature-content">
              <p>L - J:  10 a 14 y 16 a 20</p>
              <p>V: 10 a 14</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
);

export const query = graphql`
query {
  site {
    siteMetadata {
      contact {
        email {
          localpart
          domain
        }
        phone
        address
      }
    }
  }
}
`;

export default Contact;